@import '../../../assets-stage.addup.network/styles/variables';

.helpText {
	color: $theme-default-text-light;
	font-size: 14px;
	margin-top: 40px;
	margin-bottom: 8px;
}

.backIcon {
	font-size: 18px;
	height: 25px;
	font-weight: bold;
	display: flex;
	align-items: center;
	background-color: white;
	padding: 4px 4px;
	border-radius: 16px;
	border: 1px solid #e1e1e1;
	margin-right: 16px;
	cursor: pointer;
	i {
		font-size: 22px;
	}
}
.link {
	display: flex;
	align-items: center;
	.icon {
		margin-right: 8px;
	}
	.text {
		cursor: pointer;
		text-decoration: underline;
		color: $theme-primary-color;
		font-size: 14px;
		font-weight: 600;
	}
}

.container {
	margin-top: 20px;
	.formContainer {
		display: grid;
		width: 360px;
		grid-template-columns: 75% 25%;
		align-items: flex-start;
		.formComponentHalf {
			margin-right: 48px;
		}
		.formComponent3_4th {
			margin-right: 16px;
		}
		.formComponent3_5th {
			margin-right: 10px;
		}
		.formComponent {
			display: flex;
			align-items: center;
			margin-bottom: 16px;
		}
		.formComponent:last-child {
			margin-right: 0;
		}
	}
}

.mobileInput {
	display: flex;
	align-items: flex-start;
	margin: 3px;

	.inputMobile {
		width: 235px;
		height: 34px;
		font-size: 15px;
	}
}

.formOtpContainer {
	.otpContainer {
		margin: 20px 0;
	}

	.otpIn {
		height: 30px;
		width: 40px !important;
		margin-right: 15px !important;
		// background-color: #f2f2f2;
		border-radius: 5px;
		border: 0.5px solid #e1e1e1 !important;

		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			-moz-appearance: textfield;
			margin: 0;
		}
	}
	.otpFocus {
		outline: none !important;
	}
	.otpError {
		border: 0.5px solid red !important;
	}
	.otpInputCustom {
		width: 295px;
		// margin-left: 5px;
		// text-align: center;
		padding-left: 15px;
		letter-spacing: 42px;
		border: 0;
		background-image: linear-gradient(
			to left,
			black 70%,
			rgba(255, 255, 255, 0) 0%
		);
		background-position: bottom;
		background-size: 50px 1px;
		background-repeat: repeat-x;
		background-position-x: 35px;
		overflow: hidden;
		// width: 220px;
	}
}
