.container {
  height: 64px;
  // width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 0px 1px 6px #2c2c2c1c;
  .contents {
    margin: 14px 24px 14px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      height: 40px;
    }
    .actions {
      display: flex;
      .profile {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        margin-left: 24px;
        background-image: url('https://lh3.googleusercontent.com/-hQ8PZ0VlxEc/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3reTZbjW9JoHlpR10ubHjBuQjpckQA.CMID/s32-c/photo.jpg');
      }
    }
  }
}
.select {
  margin-left: auto;
  margin-right: 22px;
  width: 176px;
  height: 32px;
}

.trackSearch {
  padding: 2px 4px;
  display: flex;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 250px;
  height: 30px;
  margin-left: 80px !important;

  .input {
    flex: 1;
    margin-left: 8px;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 12px;

    ::-webkit-input-placeholder {
      font-style: normal !important;
      padding-left: 20px !important;
    }
    ::-moz-placeholder {
      font-style: normal !important;
      padding-left: 20px !important;
    }
    :-ms-input-placeholder {
      font-style: normal !important;
      padding-left: 20px !important;
    }
    ::placeholder {
      font-style: normal !important;
      padding-left: 20px !important;
    }
  }

  .iconButton {
    padding: 3px;
    // background-color: #00a29d;
    background-color: #00358d;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-right: -4px;
    margin-top: -3px;

    .searchIcon {
      color: #fff !important;
    }
  }

  .MuiInputBase-input {
    text-align: center !important;
    font-style: normal !important;
  }
}

.notification {
  color: #132f57;
  margin-right: 20px;
  line-height: 35px;
}

.RedirectBtn {
  cursor: pointer;
  width: 100%;
}

.notificationHead {
  display: flex;
  .IconDiv {
    display: flex;
    p {
      margin: 2px;
    }
  }
  h4 {
    margin: 2px;
  }
}
.TimeStyle {
  color: #c2c1c0;
  font-size: 12px;
  float: right;
  margin-top: -20px;
}
