@import '../../../../assets-stage.addup.network/styles/variables';

.table {
	table-layout: fixed;
	&__head {
		background: transparent;
		border-bottom: none;
		th {
			background: #fafafa;
		}
	}

	&__body {
		background: #fff;

		// font-weight: bold !important;
		// box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.16);

		tr:hover {
			background: #f5f5f5;
		}
	}

	.tableCell {
		overflow-wrap: break-word;
	}
}

.CustomtbodySize {
	font-size: 12px !important;
	color: #4b4b4b;
}
.rowlink {
	text-decoration: underline !important;
	color: $theme-primary-color;
}

.sortIcon {
	height: 16px;
}

.noRecords {
	padding: 16px;
}
