.container {
    width: 100%;
    max-width: 644px;
    margin: 0 auto;
    padding-top: 64px;
    .icon {
        width: 100%;
        height: 450px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .content {
        text-align: center;
        .heading {
            font-size: 72px;
            text-align: center;
            margin: 32px 0px;
        }
        .subtitle {
            font-size: 24px;
            text-align: center;
        }
        .action {
            width: 192px;
            margin: 32px auto;
            border-radius: 16px;
            .actionText {
                margin-left: 4px;
            }
        }
    }
}

@media screen and (max-width: 644px) {
    .container {
        width: 100%;
        max-width: 644px;
        margin: 0 auto;
        .icon {
            width: 100%;
            height: 250px;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
        }
        .content {
            text-align: center;
            .heading {
                font-size: 48px;
                text-align: center;
                margin: 16px 0px;
            }
            .subtitle {
                font-size: 20px;
                text-align: center;
            }
            .action {
                width: 192px;
                margin: 16px auto;
                border-radius: 16px;
                .actionText {
                    margin-left: 4px;
                }
            }
        }
    }
}