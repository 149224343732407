.success{
    background-color: #43a046 !important;
}
.error{
    background-color: #d32f2f !important;
}
.info{
    background-color: #f99f02 !important;
}
.warning{
    background-color: #2e76d2 !important;
}
.icon{
    // font-size: 20px;
}
.iconVariant{
    opacity: 0.9;
    margin-right: 16px;
}
.message{
    display: flex;
    align-items: center;
    font-size: 16px;
}
.margin{
    margin: 16px;
}