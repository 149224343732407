@import '../../../../assets-stage.addup.network/styles/variables';

.container {
  position: absolute;
  top: 0px;
  width: 285px;

  height: 100vh;

  overflow-x: hidden;
  bottom: 0;
  z-index: 1000;
  margin-top: 0;
  scrollbar {
    width: 4px;
    height: auto;
    overflow-y: scroll;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  // background-color: #fff;
  background: #00358d;
  // background: #00d4cd;

  transition: all 0.3s ease-in-out;
  .headerIcon {
    width: 200px;
    margin-bottom: 5px;
    // background-color: #fff;
    // height: 70px;
    // width: 260px;
  }
  .logo {
    margin-left: 7px;
    margin-top: 10px;
    height: 40px;
  }
  .logo1{
    margin: 5px 7px;
    height: 40px;
  }
  .navigations {
    list-style: none;
    margin: 0;
    padding: 4px 0;

    .nav {
      cursor: pointer;
      border-left: 2px solid transparent;
      .link {
        padding: 10px 12px;
        margin: 0;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #fff;
        // color: $theme-default-text;
        font-size: 15px;

        .drsIcon {
          margin-left: -2px !important;
          margin-right: 2px !important;
        }
        span {
          flex: 1;
          padding: 4px 0;
          margin-left: 8px;
        }
      }
    }
    .active {
      fill: $theme-primary-color;
      // color: $theme-default-text-dark;
      color: #fff;
      background-color: rgba(255, 255, 255, 0.2);
      font-weight: bold;
      // border-left: 3px solid $theme-primary-color;
      border-left: 4px solid #fff;
    }
    .nav:hover {
      // background-color: #dadada;
      background-color: rgba(255, 255, 255, 0.2);
      // background: transparent linear-gradient(270deg, #003a8a 7%, #00a29d 80%)
      // 	0% 0% no-repeat padding-box;
    }
  }
  .level2 {
    transition: all 0.2s;
    // overflow: hidden;
    max-height: 0;
    display: none;
    .link {
      padding: 4px 14px !important;
    }
    .nav {
      padding-left: 27px;
    }
    .active {
      // fill: $theme-primary-color;
      fill: #fff;
      // color: $theme-default-text-dark;
      color: #fff;
      font-weight: bold;
      // border-left: 2px solid $theme-primary-color;
      border-left: 4px solid #fff;
    }
  }

  .level3 {
    transition: all 0.2s;
    // overflow: hidden;
    max-height: 0;
    display: none;
    .link {
      padding: 4px 14px !important;
    }
    .nav {
      padding-left: 54px;
    }
    .active {
      // fill: $theme-primary-color;
      fill: #fff;
      // color: $theme-default-text-dark;
      color: #fff;
      font-weight: bold;
      // border-left: 2px solid $theme-primary-color;
      border-left: 4px solid #fff;
    }
  }
  .open {
    max-height: 375px;
    display: block;
  }
  .sidebarAction {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
    }
  }
}

.collapse {
  width: 56px;
  .navigations {
    .nav {
      .link {
        span {
          display: none;
        }
        i {
          display: none;
        }
      }
    }
  }
  .level2 {
    .nav {
      padding-left: 0px;
      span {
        display: none;
      }
    }
  }
  .sidebarAction {
    img {
      transform: rotateY(180deg);
    }
  }
}
