@import '../../../../assets-stage.addup.network/styles/variables';

.container {
	margin-top: 70px;
	margin: 0px;
	position: relative;

	max-height: 100vh;
	overflow: hidden;
	.blurScreen {
		z-index: 98;
		position: fixed;
		width: 100%;
		height: 100vh;
		background-color: rgba(255, 255, 255, 0.6);
	}
	.routes {
		height: calc(100vh - 70px);
		overflow: auto;
		margin-top: 70px;
		background-color: #fafafa;
		transition: all 0.2s;
		z-index: 79;
	}
	.full {
		margin-left: 56px;
	}
	.partial {
		margin-left: 200px;
	}
}
