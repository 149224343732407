@import '../../../../assets-stage.addup.network/styles/variables';

.backdrop {
    position: fixed;
    background: rgba(0,0,0,0.4);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    &__container {
        width: 300px;
        margin: 10% auto;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.16);
        padding: 16px;
        text-align: center;
        &__title {
            font-size: 20px;
            color: $theme-default-text;
        }
        &__subtitle {
            font-size: 16px;
            color: $theme-default-text-light;
        }
    }
}
