@import '../../../assets-stage.addup.network/styles/variables';

.container {
	padding: 14px 24px 24px 24px;
	color: $theme-default-text;
	background-image: url('../../../assets-stage.addup.network/images/Image@2x.png');
	background-repeat: no-repeat;
	background-size: contain;
	height: calc(100vh - 118px);
	.heading {
		color: $theme-default-text-dark;
		font-family: sans-serif;
		font-size: 20px;
		font-weight: bold;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap:20px;
		margin-top: 16px;
		margin-bottom: 16px;
		.actions {
			display: flex;
			.search {
				margin-right: 14px;
			}
		}
	}
	.searchContainer {
		display: flex;
		// justify-content: center;
		padding-left: 100px;
		.search {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 24px;
			margin-left: -100px;
			width: 500px;
			.actionInfo {
				font-size: 11px;
				font-weight: bold;
				color: #2c2c2c;
				margin-bottom: 12px;
			}
			.actionHint {
				margin-top: 8px;
				font-size: 11px;
				color: #8e8e8e;
			}
		}
	}
}

.content {
	max-width: 50%;
	margin: 0 auto;
}

.detailsContainer {
	height: calc(100% - 28px);
	.header {
		display: flex;
		align-items: center;
		.icon {
			background-color: white;
			padding: 4px 4px 0;
			border-radius: 16px;
			border: 1px solid #e1e1e1;
			margin-right: 16px;
			cursor: pointer;
			i {
				font-size: 22px;
			}
		}
		.id {
			font-size: 22px;
			font-weight: bold;
			color: #2c2c2c;
		}
		font-size: 22px;
		// .title {
		// }
		.value {
			font-weight: bold;
		}
	}
	.paper {
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #e1e1e1;
		border-radius: 4px;
		margin: 24px 0px;
		display: flex;
		min-height: calc(100% - 78px);
		.caption {
			color: #2c2c2c;
			font-weight: bold;
			font-size: 16px;
			margin-bottom: 18px;
		}
		.bookingDetails {
			padding: 24px;
			border-right: 1px solid #e1e1e1;
			.detailGroup {
				margin-bottom: 16px;
				width: 207px;
				.title {
					color: #8e8e8e;
					font-size: 11px;
					margin-bottom: 4px;
				}
			}
		}
		.trackingEvent {
			padding: 24px;
			flex: 1;
		}
	}
}

.stickyAction {
	
	width: 100px;
	font-weight: bold !important;
	font-size: 15px !important;
	
	
}

.searchInput {
	background-color: white;
	padding: 14px;
}

.logo {
	height: 44px;
	margin-bottom: 16px;
}

.paperBox {
	box-shadow: 0px 3px 6px #00000029;
	border: 1px solid #eaeaea;
	border-radius: 10px;
	padding: 14px 26px;
	margin-top: 15px;
}

.trackingInfoHeading,
.trackingInfoValue {
	color: #6e6e6e;
	font-family: 'Proxima-Nova';
	font-size: 14px;
	text-align: left;
}

.trackingInfoValue {
	color: #2c2c2c;
	font-size: 14px;
}

// .circle {
//   width: 30px;
//   height: 30px;
//   border-radius: 50%;
//   z-index: 10;
//   background-color: '#fff';
// }
.orderDetails{
	// style={{
	// 	display: 'flex',
	// 	flexDirection: 'column',
	// 	justifyContent: 'space-between',
	// 	flexWrap:'wrap'
	//   }}
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
}
.uncolored,
.colored {
	color: #fff;
	width: 14px;
	height: 14px;
	font-size: 12px;
	background-color: rgba(211, 211, 211, 0.8);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.colored {
	background-color: #36b964;
}

.vrLogo {
	height: 60px;
}

.trackStatus {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.trackStatusText {
	text-align: center;
	color: #2c2c2c;
	font-size: 14px;
	font-family: 'Proxima-Nova';
}

.progressContainer {
	margin: 10px 20px;
	width: 93%;
}

.items {
	display: flex;
}

.items:first-child {
	padding-bottom: 8px;
}
.mapContainer{
	height: calc(100vh - 400px);
	padding: 10px;
	
	background-color:#E5E4E2;
	border-radius:10px;
	margin-bottom:20px
}
.mapContainer1{
	height: calc(100vh - 400px);
	padding: 10px;
	display: flex;

	align-items: center;
	justify-content: center;
	background-color:#E5E4E2;
	border-radius:10px;
	margin-bottom:20px
}

@media (max-width:900px) {
	.mapContainer1{
		height: calc(100vh - 500px);
		padding: 10px;
		display: flex;
		width: 365px;
		align-items: center;
		justify-content: center;
		background-color:#E5E4E2;
		border-radius:10px;
		margin-bottom:20px
	}
	.container {
	
		.heading {
			color: $theme-default-text-dark;
			font-family: sans-serif;
			font-size: 15px;
			font-weight: bold;
			display: flex;
			justify-content: flex-start;
			gap:20px;
			margin-top: 16px;
			margin-bottom: 16px;
			.actions {
				display: flex;
				.search {
					margin-right: 14px;
				}
			}
		}
		.searchContainer {
			display: flex;
			// justify-content: center;
			flex-direction: row;
			
			.search {
				display: flex;
				flex-direction: row;
				margin-left: -100px;
				margin-bottom: 24px;
				
				.actionInfo {
					font-size: 5px;
					font-weight: bold;
					color: #2c2c2c;
					margin-bottom: 12px;
				}
				.actionHint {
					margin-top: 8px;
					font-size: 11px;
					color: #8e8e8e;
				}
			}
		}
	}
	.vrLogo {
		height: 35px;
	}
	.searchBar{
			display: flex;
			flex-direction: column;
			height: 100%;
			width: 300px;
	}
	.content {
		max-width: 50%;
		margin: 0;
	}
	.stickyAction {
		
		width: 55px;
		font-weight: bold !important;
		font-size: 12px !important;
		
	}
	
	.searchInput {
		background-color: white;
		width: 200px;
	
	}
	.mapContainer{
		height: calc(100vh - 500px);
		padding: 5px;
		background-color:#E5E4E2;
		border-radius:10px;
		margin-bottom:10px;
		width: 365px;
	}
	.paperBox {
		box-shadow: 0px 3px 6px #00000029;
		border: 1px solid #eaeaea;
		border-radius: 10px;
		width: 360px;
	}
	.progressContainer {
		margin: 10px 20px;
		width: 93%;
	}
	.trackStatusText {
		text-align: center;
		color: #2c2c2c;
		font-size: 10px;
		font-family: 'Proxima-Nova';
		font-weight: bold;
	}
	.trackStatus {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap:30px
	}
	.detailsContainer {
		height: calc(100% - 28px);
		.header {
			display: flex;
			align-items: center;
			.icon {
				background-color: white;
				padding: 4px 4px 0;
				border-radius: 16px;
				border: 1px solid #e1e1e1;
				margin-right: 16px;
				cursor: pointer;
				i {
					font-size: 22px;
				}
			}
			.id {
				font-size: 22px;
				font-weight: bold;
				color: #2c2c2c;
			}
			font-size: 22px;
			// .title {
			// }
			.value {
				font-weight: bold;
			}
		}
		.paper {
			background: #ffffff 0% 0% no-repeat padding-box;
			border: 1px solid #e1e1e1;
			border-radius: 4px;
			margin: 24px 0px;
			width: 365px;
			display: flex;
			min-height: calc(100% - 78px);
			.caption {
				color: #2c2c2c;
				font-weight: bold;
				font-size: 16px;
				margin-bottom: 18px;
			}
			.bookingDetails {
				padding: 24px;
				border-right: 1px solid #e1e1e1;
				.detailGroup {
					margin-bottom: 16px;
					width: 207px;
					.title {
						color: #8e8e8e;
						font-size: 11px;
						margin-bottom: 4px;
					}
				}
			}
			.trackingEvent {
				padding: 24px;
				flex: 1;
			}
		}
	}
	.orderDetails{
		// style={{
		// 	display: 'flex',
		// 	flexDirection: 'column',
		// 	justifyContent: 'space-between',
		// 	flexWrap:'wrap'
		//   }}
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}
